import type { ResponseError } from '@/types/openapiRuntime'
import type { UserShopGroupDto } from '@peter-park/shop'
import type { MaybeRef } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { useOpenApiClient } from './useOpenApiClient'

export function useGetShopByPath(path: MaybeRef<string>, isEnabled = true) {
  const { apiInstance } = useOpenApiClient()
  const {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    refetch,
    suspense,
  } = useQuery({
    queryKey: ['getShopByPath', path],
    queryFn: () => apiInstance.value.shopControllerGetOneByPath({ path: unref(path) }),
    enabled: isEnabled,
    networkMode: 'always',
  })

  const httpStatus = computed(() => {
    return (error.value as ResponseError)?.response?.status
  })

  const availableUserShopGroups = computed(() => {
    // check if the group is visible in shop and has no plates
    return data.value?.userShopGroups?.filter((group: UserShopGroupDto) => group.visible && group.plates.length === 0) ?? []
  })

  const visibleUserShopGroups = computed(() => {
    return data.value?.userShopGroups?.filter((group: UserShopGroupDto) => group.visible && group.plates.length > 0) ?? []
  })

  return {
    getShopByPathIsLoading: isLoading,
    getShopByPathIsFetching: isFetching,
    getShopByPathIsError: isError,
    getShopByPathIsSuccess: isSuccess,
    getShopByPathError: error,
    getShopByPathRefetch: refetch,
    shopByPath: data,
    suspenseGetShopByPath: suspense,
    getShopByPathHttpErrorStatus: httpStatus,
    availableUserShopGroups,
    visibleUserShopGroups,
  }
}
