<script lang="ts" setup>
defineOptions({
  name: 'UiButton',
})
const props = withDefaults(defineProps<ButtonProps>(), {
  color: 'primary',
  disabled: false,
  size: 'md',
})
type Size = 'sm' | 'md' | 'lg'
type Color = 'primary' | 'primary-gradient' | 'secondary' | 'default' | 'primary-inset'
interface ButtonProps {
  color?: Color
  size?: Size
  disabled?: boolean
}
// Possibility to add more color
const colorClassesMap = new Map<Color, string>([
  ['primary', 'bg-primary-900 color-white hover:bg-purple-700 '],
  ['primary-gradient', 'bg-gradient-purple color-white !bg-transition-gradient-to-r transition-background-position transition-duration-500'],
  ['secondary', 'bg-white color-black hover:bg-gray-50 text-gray-700'],
  ['default', 'bg-gray-50 color-black text-gray-600 hover:bg-gray-100 hover:text-gray-700'],
  ['primary-inset', 'bg-transparent color-primary-900 border border-primary-900 hover:bg-primary-900 hover:color-white'],
])
const sizeClassesMap = new Map<Size, string>([
  ['sm', 'h-9'],
  ['md', 'h-11'],
  ['lg', 'h-14'],
])

const disabledClasses = '!bg-gray-100 !color-gray-50 cursor-not-allowed !bg-none'
</script>

<template>
  <button
    class="w-full flex items-center justify-center rounded-full transition duration-300 ease-in-out"
    :class="[
      colorClassesMap.get(props.color),
      sizeClassesMap.get(props.size),
      disabled ? disabledClasses : '',
    ]"
    :disabled="props.disabled"
  >
    <slot />
  </button>
</template>
