<script setup lang="ts">
import type { Translations } from '@/types'
import type { PartialAreaEntity } from '@peter-park/shop'

defineOptions({
  name: 'ParkingPermissionsCard',
})

const { areas = [], tag, translations } = defineProps<{
  /**
   * object containing translations for the ticketTitle
   * @example ```typescript
    {
      en: { title: 'Long Term Parking Ticket' },
      de: { title: 'Dauer Parkticket' }
    }
     ```
   */
  translations: Translations
  /**
   * The areas where the ticket is valid
   * @example ['Area 1', 'Area 2']
   */
  areas?: PartialAreaEntity[]
  /**
   * The tag of the ticket
   * @example 'Corporate Parking'
   */
  tag?: string
}>()

const emits = defineEmits<{
  selectGroup: []
}>()

const { t } = useI18n()

const formattedAreas = areas.map(area => area.name)

function onAddPlateClick() {
  emits('selectGroup')
}
</script>

<template>
  <UiGradientBorder class="w-inherit">
    <div class="w-full flex flex-col gap-4 p-4 sm:flex-row sm:gap-8 sm:px-8 sm:pb-8 sm:pt-6">
      <div class="w-full flex flex-col gap-4 sm:max-w-50%">
        <span class="flex items-center gap-2 text-3 text-purple-700 font-semibold leading-4.5">
          <div class="i-lucide:circle-parking h-4 w-4" />
          {{ tag || t('parking_permissions_card.ticket_tag') }}
        </span>
        <UiDynamicTranslation class="break-words text-7.5 text-gray-900 font-semibold leading-9.5" as="span" :translations="translations" translation-key="title" />
        <span class="text-sm text-gray-600 font-bold">{{ t('parking_permissions_card.valid_for') }}:</span>
        <MultiAreaPill :areas="formattedAreas" boxed :view-limit="2" />
      </div>

      <div class="w-full flex flex-col justify-end gap-6">
        <div class="flex flex-col gap-2 sm:gap-4">
          <span class="text-4 font-bold leading-6"> {{ t('parking_permissions_card.next_steps.title') }}:</span>
          <span class="flex items-center gap-2 text-sm text-gray-600">
            <div class="i-lucide:check-circle h-4 min-h-4 min-w-4 w-4 text-purple-800" />
            {{ t('parking_permissions_card.next_steps.step_1') }}
          </span>
          <span class="flex items-center gap-2 text-sm text-gray-600">
            <div class="i-lucide:check-circle h-4 min-h-4 min-w-4 w-4 text-purple-800" />
            {{ t('parking_permissions_card.next_steps.step_2', { area: 'TBD Area Name when BE Ready P1' }) }}
          </span>
        </div>
        <UiButton color="primary-gradient" size="md" @click="onAddPlateClick">
          {{ t('parking_permissions_card.add_plate') }}
        </UiButton>
      </div>
    </div>
  </UiGradientBorder>
</template>
