<script lang="ts" setup>
defineOptions({
  name: 'AppHeaderHamburger',
})

const props = withDefaults(defineProps<{
  mobileMenuOpen: boolean
}>(), {
  mobileMenuOpen: false,
})
const emits = defineEmits<{
  'update:mobileMenuOpen': [value: boolean]
}>()

function handleUpdateMobileMenuOpen(value: boolean) {
  emits('update:mobileMenuOpen', value)
}
</script>

<template>
  <div class="flex sm:hidden">
    <AppHeaderButton color="gray" @click="handleUpdateMobileMenuOpen(!props.mobileMenuOpen)">
      <div :class="props.mobileMenuOpen ? 'i-lucide:x' : 'i-lucide:menu'" />
    </AppHeaderButton>
  </div>
</template>
