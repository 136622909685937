<script setup lang="ts">
defineOptions({
  name: 'GradientBorder',
})

const { borderSize = 1, radius = 24, color = 'primary', fitting = 'fit' } = defineProps<{

  /**
   * border size in pixels
   */
  borderSize?: number

  /**
   * radius size in pixels
   */
  radius?: number

  /**
   * color of the border
   */
  color?: Color
  /**
   * color of the border
   */
  fitting?: Fitting

}>()
type Fitting = 'fit' | 'full'
type Color = 'primary' | 'secondary' | 'warning'
const innerBorderStyle = computed(() => {
  return `border-radius: ${radius}px;`
})

const outerRadiusStyle = computed(() => {
  return `border-radius: ${radius + borderSize}px; padding: ${borderSize}px;`
})

const colorClassesMap = new Map<Color, string>([
  ['primary', 'to-purple-600'],
  ['secondary', 'to-gray-600'],
  ['warning', 'to-warning-400'],
])

const fittingClassMap = new Map<Fitting, string>([
  ['fit', 'w-fit'],
  ['full', 'w-full'],
])
</script>

<template>
  <div
    class="h-fit content-center justify-items-center from-gray-25 from-10% to-50% bg-gradient-to-br bg-transition-gradient-to-rb shadow-custom-md transition-all transition-duration-500 hover:shadow-custom-lg"
    :class="[
      colorClassesMap.get(color),
      fittingClassMap.get(fitting),
    ]"
    :style="outerRadiusStyle"
  >
    <div class="w-full content-center justify-items-center bg-gray-25" :style="innerBorderStyle">
      <slot />
    </div>
  </div>
</template>
